// Earnings Component Styles

// Variables
// =================================
@import "../../styles/_color-palette";
@import "../../styles/_mixins";
@import "../../styles/variables";

// Content
// ==================================

.table {
  &.with-total { // table footer with addition line / horizontal separator
    &::after {
      height: 1em;
      display: table-row;
      content: '';
    }

    tfoot {
      &::before {
        height: 1em;
        display: table-row;
        content: '';
        border-top: 1px solid $border-color__light;
      }
    }
  }

  .numeric { // numeric data should be aligned right
    text-align: right;
  }
}
