// Variables
// =================================
@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/color-palette.scss";

.section-au {

	.link-more {
		color: $primary-theme-color;

		&:hover {
			color: $primary-theme-color;
		}
	}

	// headings
	// ==================================

	h1,
	h2,
	h3 {
		font-family: $font-bold;
	}

	// Content
	// ==================================

	.download-button-top {
		margin-top: 10px;

		.btn.btn-outline-primary {
			width: 130px;
		}

		.dropdown-menu {
			min-width: 130px;
		}
	}

	.heading-w-img {
		@include theme-aware("background-color", "table-background");
		@include theme-aware("box-shadow", "shadow");
		border-radius: 15px;
		padding: 25px;

		.image-col {
			margin-right: 20px;
			width: 50px;
			height: 50px;

			@include border-radius(50%);
			background-color: $primary-theme-color-70;

			img {
				@include border-radius(50%);
				@include theme-aware("box-shadow", "shadow-dark");
				max-width: 100%;
				width: 50px;
				height: 50px;
			}

			.social-icon-img {
				position: relative;
				bottom: 45px;
				left: 85px;

				width: 50px;
				height: 50px;
				border: 3px solid white;
			}

			@media (max-width: 648px) {
				.social-icon-img {
					position: relative;
					bottom: 20px;
					left: 30px;

					width: 30px;
					height: 30px;
					background-color: white;
				}
			}
		}

		.heading {
			overflow: hidden;
			*overflow: visible;
			zoom: 1;
			color: $color-light-grey;
			text-align: center;

			h1 {
				font-size: 20px;
				margin-bottom: 0;
				padding-top: 25px;
				@include theme-aware("color", "title");

				span {
					position: relative;
					padding-right: 32px;
					@include theme-aware("color", "title");

					// &:after {
					// 	content: "";
					// 	position: absolute;
					// 	right: 0;
					// 	top: 1px;
					// 	width: 20px;
					// 	height: 20px;
					// 	background: url(../../img/verified.svg) no-repeat 0 2px;
					// 	background-size: 18px;
					// }
				}
			}

			a {
				color: $primary-theme-color;
			}

			.sub-head {
				margin-bottom: 5px;
			}

			.bottom {
				.btn-link {
					color: $primary-theme-color;
				}
			}

			.icon-container {
				padding-right: 10px;

				path {
					fill: #8896a5;
				}
			}
		}
	}

	.bottom.contact-link {
		margin-top: 20px;
	}

	.reports-profile-button-group {
		margin-top: 35px;

		form {
			display: inline-block;
		}

		button {
			margin: 0px 5px;
		}
	}

	// Tabs section
	// ==================================

	.section-inf {
		padding-top: 10px;
	}

	.iap-tabber {
		padding: 10px 0 20px;

		.h-row {
			@include theme-aware("border-color", "border-color-light");
			margin-bottom: -1rem;
		}

		.nav-pills {
			@include theme-aware("border-color", "border-color-light");
			border-bottom: 1px solid;
		}

		.nav-pills .nav-link {
			border-radius: 0.5rem;
			color: $highchart-theme-blue;
			position: relative;
			margin: 0 15px;
			padding-left: 0;
			padding-right: 0;
			cursor: pointer;

			&.active {
				background: transparent;
				@include theme-aware("color", "title");

				&.toggle {
					color: $color-white;
				}

				&:after {
					content: "";
					background: $highchart-theme-blue;
					position: absolute;
					bottom: -1px;
					width: 100%;
					left: 0;
					height: 2px;
				}
			}

			&:hover {
				@include theme-aware("color", "title");
			}
		}
	}

	.graph-wrap {
		text-align: center;
		overflow: visible !important;

		img {
			max-width: 100%;
			height: auto;
			border-radius: 50%;
		}

		&.mapHeight {
			height: 400px;
		}

		.mapContainer {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 10px;
			right: 10px;

			.popup_logo {
				width: 35px;
				height: 35px;
				border-radius: 0;
				margin-bottom: 10px;
			}
		}
	}

	.row.row-xs {
		margin-left: -10px;
		margin-right: -10px;

		[class*="col-sm-"],
		[class*="col-md-"],
		.col-6 {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.iap-tabber {
		.tab-content {
			padding: 30px 0 0;

			.card {
				margin-bottom: 20px;
				flex-grow: 1;

				a.card-link {
					height: 300px;
					overflow: hidden;

					.card-img {
						height: 100%;
						object-fit: cover;
						object-position: top;
					}
				}
			}
		}
	}

	span.rank-wrap {
		font-size: $font-size-default;
		font-family: $font-bold;
		padding-left: 10px;

		&:before {
			content: "";
			position: relative;
			width: 0;
			height: 0;
			display: inline-block;
			top: -2px;
		}

		&.rank-up {
			&:before {
				margin-right: 5px;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 6px solid #00864e;
			}
		}

		&.rank-down {
			&:before {
				margin-right: 5px;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 6px solid #932338;
			}
		}

		&.rank-neutral {
			color: $color-blue;
			font-family: $font-bold;
		}
	}

	.card.card-content-custom {
		@include theme-aware("background-color", "table-background");
		@include theme-aware("box-shadow", "shadow");
		border: 0;
		border-radius: 15px;

		h2,
		h3 {
			font-size: 20px;
			@include theme-aware("color", "title");
		}

		h2 {
			display: inline;
		}

		h2 span {
			vertical-align: middle;
			margin-bottom: 5px;
		}
	}

	.btn.btn-link {
		padding: 0;
		color: $primary-theme-color;
	}

	.card.card-content-custom {
		.card-title {
			color: $color-light-grey;
			margin-bottom: 15px;
		}

		&.tracking-account {
			border: 2px solid $primary-theme-color;

			.tracking-profile {
				margin-right: 15px;
			}

			.tracking-influencer {
				font-family: $font-bold;
				font-size: 18px;
			}

			.card-title {
				margin: 0;
			}
		}

		.card-body {
			@include theme-aware("color", "title");
		}

		.bottom {
			margin-top: 50px;

			&.view_more {
				margin-top: 15px;
			}

			&.engagement_chart {
				margin-top: 0;
			}

			.btn.btn-link {
				padding: 0;
				color: $highchart-theme-blue;
			}
		}

		.card-list-items {
			.avatar {
				width: 50px;
				height: 50px;
				float: left;
				margin-right: 10px;

				img {
					width: 100%;
					height: 100%;
					@include border-radius(50%);
				}
			}

			.list-content {
				overflow: hidden;
				*overflow: visible;
				zoom: 1;

				a {
					color: $highchart-theme-blue;
					font-family: $font-medium;
				}

				color: $color-light-grey;
			}

			li:last-child {
				margin-bottom: 0;
			}

			.list-group-item {
				background-color: transparent;
				padding: 0;
				border: 0;
				margin-bottom: 15px;

				p {
					margin-bottom: 0;
					font-size: $font-size-small;
				}
			}

			.youtube {
				padding-top: 15px;

				p {
					font-size: 16px !important;
				}
			}
		}
	}

	.list-group {
		.list-group-item {
			@include theme-aware("color", "title");
			background-color: transparent;
		}
	}

	.total-posts {
		cursor: default;
		font-weight: bold;
		pointer-events: none;
	}

	.block-last-posts {
		.card-text {
			float: left;
		}
	}

	.like-comment {
		padding: 0;
		padding-top: 5px;

		li {
			display: inline-block;
			font-size: $font-size-small;
			margin-left: 0;
			margin-right: 10px;
			padding-left: 14px;

			// &.like {
			// 	color: $highchart-theme-blue;
			// 	background: url(../../img/heart-icon.svg) no-repeat 0 2px;
			// 	background-size: 10px;
			// }

			// &.comment {
			// 	color: $highchart-theme-red;
			// 	background: url(../../img/comments-icon.svg) no-repeat 0 2px;
			// 	background-size: 10px;
			// }
		}
	}

	.popular-tags {
		ul {
			padding: 0;
			margin-bottom: 0;
		}

		li {
			@include theme-aware("background-color", "tags-background");
			@include theme-aware("color", "title");
			display: inline-block;
			margin-right: 6px;
			margin-bottom: 10px;
			display: inline-block;
			@include border-radius(4px);
			font-size: $font-size-small;
			padding: 5px 10px;
			white-space: nowrap;
		}

		.card.card-content-custom & + .bottom {
			margin-top: 10px;
		}
	}

	.ba-list {
		li {
			display: block;
			border: 0;
			padding: 0;
			margin-bottom: 10px;

			.text-wrap {
				@include theme-aware("color", "title");
				overflow: hidden;
				*overflow: visible;
				zoom: 1;
			}

			.img-col-left {
				float: left;
				width: 35px;
				height: 35px;
				margin-right: 8px;
				background: white;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				border-radius: 50%;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.table.modal-table {
		table {
			border-color: $border-color__light;
		}

		thead th {
			border-top: 0;
			border-bottom: 1px solid $border-color__light;
			color: $color-light-grey;
			font-size: $font-size-small;
			font-weight: 500;
			padding: 5px;
		}

		th,
		td {
			padding: 10px 5px;
			border-top: 1px solid rgba($border-color__light, 0.25);
			font-size: $font-size-small;
			color: $color-light-grey;
		}

		th {
			@include theme-aware("color", "title");
		}

		td {
			vertical-align: middle;
			padding: 5px;

			a {
				color: $color-blue;
			}
		}

		.tb-head {
			color: #000;

			h4 {
				margin-bottom: 0;
			}

			.country-text-wrap {
				margin-top: 6px;
			}
		}
	}

	.tb-media-left {
		width: 55px !important;
		padding-left: 0 !important;
		padding-right: 5px !important;

		img {
			max-width: 50px;
		}
	}

	// Section Audience
	// =====================================
	.section {
		padding: 20px 0 0;
	}

	.section-head {
		margin-bottom: 25px;

		h2 {
			font-size: 32px;
		}
	}

	.follower-like-tabs {
		.nav-pills {
			border-bottom: 0;

			.nav-link {
				// @include theme-aware("background", "table-background");
				// @include theme-aware("border", "hover");
				// border: 1px solid $highchart-theme-blue;
				padding: 7.5px 15px;
				margin: 0px;
				width: 125px;
				text-align: center;
				cursor: pointer;

				&:after {
					display: none;
				}

				&.active {
					// background: $highchart-theme-blue;
					// color: $color-white;
				}
			}

			.nav-item {
				&:first-child a {
					border-right: 0;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				&:last-child a {
					border-left: 0;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}

	.icon-unlike,
	.icon-liked,
	.icon-neutral {
		position: relative;
		padding-left: 30px;

		&:before {
			position: absolute;
			left: 0;
			content: "";
			background-size: 20px;
			width: 20px;
			height: 20px;
		}
	}

	// .icon-unlike:before {
	// 	background: url(../../img/dislike.png) no-repeat 0 0;
	// 	background-size: contain;
	// 	top: 4px;
	// }

	// .icon-liked:before {
	// 	background: url(../../img/001-like.svg) no-repeat 0 0;
	// 	background-size: contain;
	// 	top: 0;
	// }

	// .icon-neutral:before {
	// 	background: url(../../img/icon-normal.png) no-repeat 0 0;
	// 	background-size: contain;
	// 	top: 0;
	// }

	.preview-accounts {
		flex: 1 1 auto;
	}

	.preview-accounts-dropdown {
		width: 100%;
	}

	.preview-accounts-dropdown .media-left img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.preview-accounts-dropdown-report {
		width: 60%;
	}

	.preview-accounts-dropdown-report .media-left img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.custom-bedge-list {
		padding-top: 15px;

		li {
			font-size: $font-size-small + 1;
			padding: 0;
			border: 0;
			margin-bottom: 8px;
			position: relative;
			padding-left: 15px;

			&:before {
				content: "";
				@include border-radius(50%);
				position: absolute;
				width: 10px;
				height: 10px;
				background: $highchart-theme-blue;
				left: 0;
			}

			&.female:before {
				background: $highchart-theme-red;
			}

			.badge {
				padding-right: 0;
			}
		}
	}

	.multiple-tbl {
		h4 {
			@include theme-aware("color", "title");
			font-size: 16px;
		}
	}

	.country-list {
		li {
			border: 0;
			padding: 0;
			margin-bottom: 8px;
		}

		&.with-progress {
			li {
				margin-bottom: 20px;
			}
		}
	}

	.country-text-wrap {
		.location-card-flag {
			width: 60px;
			height: 60px;
			float: left;
			// background: #000;
			margin-right: 8px;

			// border: 2px solid #f0f0f0;
			// margin-top: 1px;
			img {
				max-width: 100%;
				vertical-align: top;
			}
		}

		.flag-img {
			width: 30px;
			float: left;
			height: 22px;
			margin-right: 8px;

			img {
				max-width: 100%;
				vertical-align: top;
			}
		}

		.brand-img {
			border: 0px;
			background: none;
		}

		.brand-name {
			@include theme-aware("color", "title");
			margin-top: 7.5px;
		}

		.interest-name {
			@include theme-aware("color", "title");
			margin-top: 7.5px;
		}

		.country-name {
			@include theme-aware("color", "title");
			overflow: hidden;
			zoom: 1;
			text-overflow: ellipsis;
			white-space: nowrap;

			h2 {
				overflow: hidden;
				zoom: 1;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.progress-bar-custom {
		margin-top: 8px;

		&.progress {
			@include theme-aware("background", "dropdown-hover");
			@include border-radius(0);
			height: 4px;
		}
	}

	.section-po {
		.react-multi-carousel-list {
			padding-top: 40px;

			.react-multiple-carousel__arrow {
				position: absolute;
				top: 0px;
				left: 50%;
				background: transparent;
				outline: none;
				min-width: 30px;
				min-height: 30px;
				max-width: 30px;

				i {
					font-size: 14px;
					color: $primary-theme-color;
				}

				&.react-multiple-carousel__arrow--left {
					margin-left: -26px;

					&:before {
						content: none;
					}
				}

				&.react-multiple-carousel__arrow--right {
					margin-left: -5px;

					&:before {
						content: none;
					}
				}

				&:hover {
					i {
						color: #000;
					}
				}
			}
		}
	}

	.carouselitem,
	.posts-grid {
		padding-left: 10px;
		padding-right: 10px;

		.card {
			@include theme-aware("background-color", "table-background");
			@include theme-aware("box-shadow", "shadow-dark");
			border: 0;
		}

		.post-date {
			font-size: $font-size-small - 2;
			color: $color-light-grey;
			padding-bottom: 8px;
		}

		.card-text {
			line-height: 1.4;
			height: 58px;
			margin-bottom: 5rem;
			@include theme-aware("color", "title");
			// overflow: hidden;
		}

		.post-bottom {
			.text-sm {
				font-size: $font-size-small;
				color: $color-light-grey;
			}

			.text-count {
				@include theme-aware("color", "title");
				font-size: $font-size-default;
				margin-bottom: 0;
			}
		}
	}

	.graph-progress.progress {
		position: absolute;
		height: 7px;
		left: 10px;
		right: 10px;
		// bottom: 44px;
		bottom: 24px;
		border-radius: 5px;
		z-index: 0;

		.progress-bar {
			background: $highchart-theme-blue;
			border-radius: 5px;
		}
	}

	.graph-progress.bottom-44.progress {
		bottom: 44px;
	}

	.highcharts-stack-labels span.img-graph-bg {
		width: 50px;
		height: 50px;
		background: $primary-theme-color-70;
		text-align: center;
		padding: 2px;
		border-radius: 50%;
		border: 1px solid #fff;
		display: block;
	}

	// trending-analysis section

	.trending-analysis-labels {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;

		span {
			color: #666;
		}
	}

	.trending-analysis-range {
		margin-bottom: 5px;

		span {
			color: #666;
		}
	}

	.ch-378 {
		height: 378px;
	}

	.ch-300 {
		height: 300px;
	}

	.ch-200 {
		height: 200px;
	}

	.progress.trending-analysis-progress {
		height: 10px;
		border-radius: 5px;

		.trending-analysis-progress-bar {
			border-radius: 5px;
			background: -webkit-linear-gradient(
				left,
				#f3211d,
				#13bbdb,
				#46cc44
			) !important;
			/* Chrome10-25,Safari5.1-6 */
		}
	}

	.progress.trending-analysis-value {
		height: 15px;
		border-radius: 0px;
		border: none;
		background: -webkit-linear-gradient(
			left,
			#f3211d,
			#13bbdb,
			#46cc44
		) !important;

		.trending-overlap-div {
			position: inherit;
			@include theme-aware("background-color", "table-background");
		}

		.trending-overlap-transparent-div {
			background-color: transparent;
		}

		.trending-analysis-progress-bar {
			// border-radius: 5px;
			background: -webkit-linear-gradient(
				left,
				#f3211d,
				#13bbdb,
				#46cc44
			) !important;
			/* Chrome10-25,Safari5.1-6 */
		}
	}

	.custom-nav-icons {
		text-align: center;

		.btn {
			box-shadow: none !important;
			padding: 10px;

			&:hover .fa {
				color: #000;
			}
		}

		.fa {
			color: $primary-theme-color;
		}
	}

	.absolute-dropdown {
		position: absolute !important;
		z-index: 1;
		right: 10px;
	}

	.story-text {
		display: flex;
		align-items: center;

		.story-icon {
			width: 20px;
			height: 20px;
		}
	}

	// Media Queries
	// =====================================

	// Desktop
	// =====================================

	@media only screen and (min-width: 576px) {
		.ch-1 {
			height: 125px;
		}

		.fix-height {
			height: 193px;
		}

		// .double-fix-height{height: 406px;}
		.ch-2 {
			height: 74px;
		}

		.ts-tag-clouds-in {
			height: 357px;
		}

		.ch-3 {
			height: 314px;
		}

		.ch-12 {
			height: 185px;
		}

		.ch-5 {
			height: 190px;
		}

		.ch-6 {
			height: 145px;
		}

		.ch-179 {
			height: 100px;
		}

		.ch-112 {
			height: 112px;
		}

		.ch-7 {
			height: 160px;
		}

		.ch-8 {
			height: 140px;
		}

		.ch-9 {
			height: 177px;
		}

		.ch-11 {
			height: 193px;
		}

		.ch-14 {
			height: 278px;
		}

		.ch-300 {
			height: 300px;
		}

		.tb-media-left {
			width: 55px !important;

			img {
				max-width: 100%;
			}
		}

		.like-comment {
			float: right;
			padding-top: 0;

			li {
				margin-left: 20px;
				margin-right: 0;
				white-space: nowrap;
			}
		}

		body .modal-dialog {
			max-width: 650px;
		}
	}

	@media only screen and (min-width: 767px) {
		.heading-w-img {
			.image-col,
			.image-col img {
				width: 125px;
				height: 125px;
			}

			.heading h1 {
				font-size: 32px;

				span:after {
					top: 10px;
				}
			}
		}

		.card.card-content-custom {
			h2,
			h3 {
				font-size: 24px;
			}
		}

		.ts-tag-clouds-in {
			height: 320px;
		}

		.ch-1 {
			height: 125px;
		}

		.ch-3 {
			height: 247px;
		}

		.ch-4 {
			height: 210px;
		}

		.ch-5 {
			height: 110px;
		}

		.ch-10 {
			height: 140px;
		}

		.ch-14 {
			height: 153px;
		}

		.ch-300 {
			height: 200px;
		}

		.table.modal-table {
			th,
			td {
				font-size: 13px;
			}
		}

		.multiple-tbl {
			h4 {
				font-size: 18px;
			}
		}
	}

	@media only screen and (min-width: 992px) {
		.ch-5 {
			height: 75px;
		}

		// .carouselitem .card a.card-link {
		// 	height: 130px;
		// }
	}

	@media only screen and (min-width: 1199px) {
		// .carouselitem .card a.card-link {
		// 	height: 160px;
		// }
	}
}
