// Variables
// =================================
@import "../../styles/color-palette";
@import "../../styles/mixins";

.deals-splash {
  &.detailed-view {
    height: 80vh;

    @media (max-width: $mobile-width) {
      height: 125vh;
    }

    h1 {
      font-size: $h1-font-size;
    }

    h2 {
      .badge {
        padding: 8px 10px;
        font-size: 60%;
      }
    }

    .brand-logo {
      @include theme-aware("box-shadow", "shadow-dark");
      border-radius: $blocks-radius;
    }

    .sticky-container {
      width: 50%;
      @media (max-width: $mobile-width) {
        @include theme-aware("box-shadow", "shadow-dark");
        @include theme-aware("background-color", "color-background");

        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;

        .button-container {
          margin: 1rem;
        }
      }
    }
  }

  .card-brand {
    border-radius: $blocks-radius;
    @media (max-width: $mobile-width) {
      transform: scale(0.8);
    }
  }

  .card.card-custom {
    &.opportunity-card {
      border: 2px solid transparent;

      &:hover {
        cursor: pointer;
        border: 2px solid $primary-button-outline-color;
      }
    }
  }
}
