.campaign-brief {
  .accordion__button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    // add gap between flex children
    gap: 0.5em;

    h3 {
      font-size: 1.2rem;
    }
  }

  .accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg);
  }

  .card-header {
    padding-top: 1em;
    cursor: pointer;
  }
}
