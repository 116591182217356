@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/color-palette.scss";

.media-kits {
  display: block;

  .img-graph-bg {
    width: 50px;
    height: 50px;
    background: $primary-theme-color-70;
    text-align: center;
    padding: 2px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    object-fit: contain;

    > img {
      border-radius: 50%;
      height: 44px;
      width: 44px;
    }
  }

  .handle-list-item {
    margin-left: auto;
    margin-right: auto;

    .card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .handle-list-item-skeleton {
    margin-bottom: 20px;
  }

  .handle-profile {
    display: flex;
    gap: 20px;
    align-items: center;
    height: 110px;

    .info-section {
      flex-grow: 1;

      > :not(:last-child) {
        margin-bottom: 5px;
      }
      > :last-child {
        margin-bottom: 0;
      }
    }
  }

  .media-kit {
    margin-top: 40px;
    margin-bottom: 20px;

    .media-kit-header {
      .card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .numeric-stats-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 20px;
    }
    .numeric-stat-card {
      max-width: 325px;
      // min-width: 200px;
      height: 133px;
    }

    .engagement-grid {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;

      .trending-analysis {
        grid-column: span 1;
        max-width: 500px;
      }

      .engagement {
        grid-column: span 2;
      }
    }

    .line-graph {
      > div {
        margin-bottom: 20px;
      }
    }
  }

  .engagement-spread {
    .card-title {
      display: flex;
      justify-content: space-between;
    }
    .like-comment {
      padding: 0;
      padding-top: 5px;
      display: flex;
      align-items: center;

      li {
        display: inline-block;
        font-size: $font-size-small;
        margin-left: auto;
        margin-right: 10px;
        padding-left: 14px;

        &.like {
          color: $highchart-theme-blue;
          background: url(../../assets/img/heart_icon.svg) no-repeat 0 2px;
          background-size: 10px;
          background-position-y: center;
        }

        &.comment {
          color: $highchart-theme-red;
          background: url(../../assets/img/comment_icon.svg) no-repeat 0 2px;
          background-size: 10px;
          background-position-y: center;
        }
      }
    }
  }

  .trend-badge.badge {
    height: 26px;
    padding: 3px 8px;
    font-size: calc(16px * 0.8);
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }

  .popular-tags {
    ul {
      padding: 0;
      margin-bottom: 0;
    }

    li {
      @include theme-aware("background-color", "tags-background");
      @include theme-aware("color", "title");
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 10px;
      display: inline-block;
      @include border-radius(4px);
      font-size: 12px;
      padding: 5px 10px;
      white-space: nowrap;
    }

    .card.card-content-custom & + .bottom {
      margin-top: 10px;
    }
  }

  // Trending Analysis Section
  // TODO: Copied from Tensor. Refactor
  .trending-analysis {
    max-width: 550px;

    .trending-analysis-labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      span {
        color: #666;
      }
    }

    .trending-analysis-range {
      margin-bottom: 5px;

      span {
        color: #666;
      }
    }

    .ch-378 {
      height: 378px;
    }

    .ch-300 {
      height: 300px;
    }

    .ch-200 {
      height: 200px;
    }

    .progress.trending-analysis-progress {
      height: 10px;
      border-radius: 5px;

      .trending-analysis-progress-bar {
        border-radius: 5px;
        background: -webkit-linear-gradient(
          left,
          #f3211d,
          #13bbdb,
          #46cc44
        ) !important;
        /* Chrome10-25,Safari5.1-6 */
      }
    }

    .progress.trending-analysis-value {
      height: 15px;
      border-radius: 0px;
      border: none;
      background: -webkit-linear-gradient(
        left,
        #f3211d,
        #13bbdb,
        #46cc44
      ) !important;

      .trending-overlap-div {
        position: inherit;
        @include theme-aware("background-color", "table-background");
      }

      .trending-overlap-transparent-div {
        background-color: transparent;
      }

      .trending-analysis-progress-bar {
        // border-radius: 5px;
        background: -webkit-linear-gradient(
          left,
          #f3211d,
          #13bbdb,
          #46cc44
        ) !important;
        /* Chrome10-25,Safari5.1-6 */
      }
    }

    .custom-nav-icons {
      text-align: center;

      .btn {
        box-shadow: none !important;
        padding: 10px;

        &:hover .fa {
          color: #000;
        }
      }

      .fa {
        color: $primary-theme-color;
      }
    }

    .absolute-dropdown {
      position: absolute !important;
      z-index: 1;
      right: 10px;
    }

    .story-text {
      display: flex;
      align-items: center;

      .story-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .lookalikes-section {
    width: 100%;
    max-width: 550px;

    .avatar-image-round {
      float: left;
    }

    .list-group-item {
      border-color: transparent;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0px;
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .ba-list {
    li {
      display: block;
      border: 0;
      padding: 0;
      margin-bottom: 10px;
      height: 35px;

      .text-wrap {
        @include theme-aware("color", "title");
        overflow: hidden;
        *overflow: visible;
        zoom: 1;
      }

      .img-col-left {
        float: left;
        width: 35px;
        height: 35px;
        margin-right: 8px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 50%;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
