@import "../../styles/color-palette";
@import "../../styles/mixins";

.modal .expanded-image-modal.modal-dialog {
  width: unset;
  max-width: 90vw;
  height: unset;
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;

  padding-top: env(safe-area-inset-top);

  display: flex;

  align-items: center;
  justify-content: center;
  .modal-content {
    width: unset;
    max-width: 90vw;
    height: unset;
    max-height: 90vh;
  }
  .modal-header {
    padding: 5px 15px;
  }

  img {
    max-height: 90vh;
    max-width: 100%;
    object-fit: contain;
  }
}

.accept-brief-form {
  .campaign-brief {
    h2 {
      font-size: 20px;
    }
  }
}

.campaign-brief-modal {
  padding-top: env(safe-area-inset-top);
}

.thumbnails {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  gap: 10px;

  & > .thumbnail-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    aspect-ratio: 1/1;
    @include border-radius($blocks-radius);
    border: 1px solid;
    @include theme-aware("border-color", "dropdown-hover");
    @include theme-aware("box-shadow", "shadow-dark");
    overflow: hidden;

    &.upload-photo-btn {
      color: $color-light-grey;
      @include theme-aware("background-color", "dropdown-background");
      transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);

      &:hover,
      &:focus {
        transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
        color: $primary-theme-color;
        border: 1px solid;
        border-color: $primary-theme-color;
        outline: transparent;
      }
    }

    .expand-media-btn {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border: 1px solid;
      overflow: hidden;
      background-color: $color-dark-grey;
      @include border-radius($blocks-radius);
      @include theme-aware("border-color", "dropdown-hover");

      &:hover,
      &:focus {
        border-color: $primary-theme-color;

        img,
        video {
          opacity: 0.5;
          transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
        }

        svg {
          transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
        color: $color-grey;
      }
    }

    .remove-media-btn {
      position: absolute;
      border: 1px solid;
      border-color: $color-dark-grey;
      color: $color-dark-grey;
      border-radius: 50%;
      background-color: rgba(240, 240, 240, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      margin: 2px;
      top: 2px;
      right: 2px;
      transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);

      &:hover,
      &:focus-visible {
        transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
        outline: transparent;
        color: $color-red;
        @include theme-aware("background-color", "dropdown-background");
        border: 1px solid;
        border-color: $color-red;
      }
    }
  }
}

.notification-dot {
  margin-left: 10px;
  padding: 5px;
  border-radius: 50%;
  background-color: $danger-button-bg-color;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    height: 10px;
    width: 10px;
  }
}

.edit-post-modal {
  padding-top: env(safe-area-inset-top);
  textarea {
    min-height: calc(
      1.5em * 10 + 6px + 6px + 1px + 1px
    ); /* 10 lines with 1.5em line height, 6px top and bottom padding, 1px top and bottom border */
  }
}

.campaign-content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  // grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-columns: 1fr;
  gap: 20px;

  @media (max-width: $viewport-md) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .campaign-content-card-wrapper {
    grid-column: auto / span 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}

.feedback-overlay {
  position: absolute;
  z-index: 20;
  top: 0;
  height: 0;
  width: 100%;
  background-color: $color-white;
  overflow: auto;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: initial;
  }
  scrollbar-width: initial;

  &.expanded {
    height: 100%;
  }

  .feedback-container {
    padding: 1rem;
  }

  .feedback-item {
    .date {
      // font-size: $font-size-small;
      margin-bottom: 0;
      color: $color-light-grey;
    }

    margin-bottom: 1.5rem;

    // if its last of its type
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.campaign-content-card {
  width: 100%;
  max-width: 800px;

  .see-more {
    background: transparent;
    border: none;
    color: $primary-theme-color;
    width: fit-content;
    margin-left: auto;
    // display: inline;
    // position:absolute;
  }
  .see-less {
    background: transparent;
    border: none;
    width: fit-content;
    color: white;
    float: right;
  }

  .expand-toggle {
    float: right;

    .expanded {
      transform: rotate(-180deg);
      transition: transform 0.2s;
    }

    .collapsed {
      transition: transform 0.2s;
    }
  }

  .expanded-caption-overlay {
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    z-index: 20;
    height: 100%;
    width: 100%;
    padding: 1rem;
    color: $color-white;
    overflow: auto;

    p {
      white-space: pre-wrap;
      padding: 20px 15px;
    }
  }

  &.preview {
    max-height: 100vh;
    max-height: 100svh;
    width: calc(min(100vw, 1300px));
    width: calc(min(100svw, 1300px));
    overflow-y: scroll;
    overflow-x: hidden;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;

    .content-body.card-body {
      min-height: clamp(750px, 100vh, 1000px);
      min-height: clamp(750px, 100svh, 1000px);
      height: clamp(750px, 100vh, 1000px);
      height: clamp(750px, 100svh, 1000px);

      .content-image-container {
        flex-grow: 1;
      }
    }
  }

  .feedback-btn.btn.btn-secondary {
    background-color: transparent;
    background: transparent;
    border-color: transparent;
    color: $primary-theme-color;
    box-shadow: none;

    &:focus {
      background-color: transparent;
    }
    &:hover,
    &:focus-visible,
    &:visited,
    &:active {
      border-color: $color-light-grey;
      background-color: transparent;
      background: transparent;
      color: $primary-theme-color;
      box-shadow: none;
    }
  }

  .card-header {
    padding: 5px 8px;
    display: flex;
    justify-content: flex-end;
  }

  .content-body.card-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    min-height: 600px;
    .content-image-container {
      width: 100%;
      height: 500px;
      max-height: 800px;
      margin-bottom: 1.8rem;

      [class*="carousel-control"] > span {
        filter: drop-shadow(2px 8px 6px rgba(0, 0, 0, 0.5))
          drop-shadow(-2px -8px 6px rgba(0, 0, 0, 0.5));
      }

      .carousel-indicators {
        bottom: -2.5rem;

        li {
          background-color: $color-dark-grey;
        }
      }

      > .carousel,
      .carousel-inner,
      .carousel-item {
        height: 100%;

        .blurred-backdrop {
          background-color: $color-dark-grey;
          background-size: cover;
          background-position: center;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 0;
          filter: brightness(45%) blur(15px);
          scale: 120%;
        }
        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
          padding: 0;
          margin: 0;
        }

        .img-skeleton {
          object-fit: contain;
          line-height: 1;
          height: 100%;
          width: 100%;
          display: block;
        }
        img.loaded + .img-skeleton {
          display: none;
        }
      }
    }

    .content-text-skeleton {
      height: 9em;
    }

    .content-text-container {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-shrink: 0;
      align-content: flex-start;

      h4 {
        font-weight: bold;
        flex: 0 0 100%;
      }

      p {
        line-height: 1.5em;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        max-height: calc(4 * 1.5em);
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .button-split {
      display: flex;
      justify-content: space-between;
      button {
        flex-basis: 48%;
      }
    }
  }
}
