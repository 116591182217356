@import "../../styles/color-palette.scss";

$progtrckr-line-height: 4.5rem;
$progtrckr-bullet-size: 2em;

.multi-step > ol.progtrckr li.progtrckr-todo[value="5"] {
  display: none;
}

.progress-stepper-nr {
  ol.progtrckr + div {
    padding: 0px 80px 30px;

    @media (max-width: $viewport-md) {
      padding: 0 5px 30px;
    }
  }

  ol.progtrckr {
    list-style-type: none;
    padding: 0;
    margin: 10px 30px 50px;

    @media (max-width: $viewport-md) {
      margin: 0 10px 40px;
    }

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    counter-reset: section;

    li {
      flex: 1;
      display: flex;
      align-items: center;

      span {
        color: $color-light-grey;
        font-family: $font-bold;
        margin: 2px 0px 2px 10px;
      }

      &:before {
        display: flex;
        justify-content: center;
        transform: translate(0, 50%);
      }

      &.progtrckr-todo[value="4"],
      &.progtrckr-doing[value="4"] {
        display: none;
      }

      &.progtrckr-todo {
        color: silver;
        border-bottom: 4px solid silver;

        &:before {
          content: counter(section);
          counter-increment: section;
          color: white;
          background-color: silver;
          width: $progtrckr-bullet-size;
          line-height: 2em;
          border-radius: $progtrckr-bullet-size;
          font-weight: bold;
        }
      }

      &.progtrckr-doing {
        color: black;
        border-bottom: 4px solid $primary-theme-color;

        &:before {
          content: counter(section);
          counter-increment: section;
          color: white;
          background-color: $primary-theme-color;
          width: $progtrckr-bullet-size;
          line-height: 2em;
          border-radius: $progtrckr-bullet-size;
        }
      }

      &.progtrckr-done {
        color: silver;
        border-bottom: 4px solid #d4edda;

        &:before {
          display: flex;
          content: "\2714 \fe0e";
          counter-increment: section;
          color: white;
          background-color: #d4edda;
          width: $progtrckr-bullet-size;
          line-height: 2em;
          border-radius: $progtrckr-bullet-size;
        }
      }
    }

    @media (max-width: 1140px) {
      li span {
        display: none;
      }
    }

    em {
      display: none;
    }
  }

  // Nav buttons
  .stepper-nav-btn {
    max-width: 200px;

    &.custom-override {
      margin-bottom: -28px;
      margin-right: -78px;
      position: absolute;
      right: 0;
      bottom: -66.5px;

      @media (max-width: 770px) {
        bottom: -106.5px;
      }

      &.secondary-next-position {
        right: 220px;

        @media (max-width: 770px) {
          right: 0;
          bottom: -45px;
        }
      }
    }
  }
}
