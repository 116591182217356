// Variables
// =================================
@import "../../styles/color-palette";
@import "../../styles/mixins";

// Content
// ==================================

.global-loading {
	position: fixed;
	left: 0;
	z-index: 1150;
}
