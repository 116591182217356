// Variables
// =================================
@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/color-palette.scss";

.section-au {

	> *:not(:last-child) {
		margin-bottom: 20px;
	}

  .grid-gender {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

		.gender {
			grid-column: span 1;
			max-width: 500px;
		}

		.age-gender {
			grid-column: span 2;
		}

    .legend-gender {
      display: inline-flex;
    }
  }

	.grid-general {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

		> * {
			max-width: 550px;
		}
	}
}
