@import "../../../../styles/color-palette";
@import "../../../../styles/mixins";

.content-upload {

    .thumbnails-skeleton {
      width: 100%;
      aspect-ratio: 1/1;
      line-height: 1;
    }

    .video-thumbnails, .video-thumbnails>span {
      display: flex;
    
      & > .thumbnail-item {
        height: 180px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include border-radius($blocks-radius);
        border: 1px solid;
        @include theme-aware("border-color", "dropdown-hover");
        @include theme-aware("box-shadow", "shadow-dark");
        overflow: hidden;
    
        &.upload-photo-btn {
          color: $color-light-grey;
          @include theme-aware("background-color", "dropdown-background");
          transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
    
          &:hover,
          &:focus {
            transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
            color: $primary-theme-color;
            border: 1px solid;
            border-color: $primary-theme-color;
            outline: transparent;
          }
        }
    
        .expand-media-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          border: 1px solid;
          overflow: hidden;
          background-color: $color-dark-grey;
          @include border-radius($blocks-radius);
          @include theme-aware("border-color", "dropdown-hover");
    
          &:hover,
          &:focus {
            border-color: $primary-theme-color;
    
            // img, video {
            //   opacity: 0.5;
            //   transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
            // }
    
            svg {
              transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
              opacity: 1;
            }
          }
    
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            padding: 0;
            margin: 0;
            opacity: 1;
            transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
          }

          video {
            max-height: calc(100% + 40px);
            width: auto;
            object-fit: cover;
            object-position: center center;
            padding: 0;
            margin: 0;
            opacity: 1;
            transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
          }
    
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
            color: $color-grey;
          }
        }
    
        .remove-media-btn {
          position: absolute;
          border: 1px solid;
          border-color: $color-dark-grey;
          color: $color-dark-grey;
          border-radius: 50%;
          background-color: rgba(240, 240, 240, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          margin: 2px;
          top: 2px;
          right: 2px;
          transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
    
          &:hover,
          &:focus-visible {
            transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
            outline: transparent;
            color: $color-red;
            @include theme-aware("background-color", "dropdown-background");
            border: 1px solid;
            border-color: $color-red;
          }
        }

        .replace-thumb-btn {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
          width: calc(100% - 2px);
          position: absolute;
          background-color: $dark-mode-background-color;
          color: $color-grey;
          bottom: 0;
          font-size: $font-size-small;
          opacity: .85;
        }
      }
    }

  .no-media {    
		display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 15px;

		.upload-media-btn {
			display: flex;
      align-items: center;
			gap: 6px;
    }
  }
}
