// Talent Portal Theme
// =================================

// colors
$primary-theme-color: #5925DC;
$primary-theme-color-50: #5925DC80;
$primary-theme-color-70: #5925DCb3;
$primary-theme-color-transparent: #5925DC1c;

$tooltip-opacity: 1;
$logo_size: 45px;
$top_logo: 92px;

// Buttons
$min-filter-button-width: 135px;

$primary-button-bg-color: $primary-theme-color;
$primary-button-shadow: 0px 3px 10px rgba(6, 10, 10, 0.32);

$primary-button-outline-color: $primary-theme-color;

$primary-button-hover-outline-color: $primary-theme-color;
$primary-button-hover-outline-shadow: 0px 3px 10px rgba(6, 10, 10, 0.32);

$primary-button-active-outline-color: darken($primary-theme-color, 5%);
$primary-button-active-outline-shadow: 0px 3px 10px rgba(6, 10, 10, 0.32);
