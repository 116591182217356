// Variables
// =================================
@import "../../styles/color-palette";
@import "../../styles/mixins";

.balance-tile {
  @media (max-width: $mobile-width) {
    margin-top: 40px;
  }
}

.card.card-content-custom {
  @include theme-aware("background-color", "table-background");
  @include theme-aware("box-shadow", "shadow");

  border: 0;

  .card-body {
    padding: 1.875rem;

    @media (max-width: $mobile-width) {
      padding: 1.45rem;
    }
  }

  .card-title {
    font-family: $font-medium;
    font-size: 18px;
    color: $color-light-grey;
    margin-bottom: 15px;
  }
}
