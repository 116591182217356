.signup-container {
  .alert > h4 {
    line-height: 24px;

    b:first-of-type::before, b:first-of-type::after {
      content: "\A";
      white-space: pre;
    }
  }
}
