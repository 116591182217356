@import "../../../styles/color-palette";
@import "../../../styles/mixins";

.social-handles-container {
  @media (max-width: 990px) {
    .social-handle:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
}

.email-verification,
.additional-info,
.stripe-onboarding {
  .title {
    font-size: $h3-font-size;
  }
}

.stripe-onboarding {
  .external-account {
    background-color: $color-grey;
    padding: 15px 18px;
    border-radius: 0.25rem;

    svg {
      color: $color-light-grey;
    }
  }
}
