// Variables
// =================================
@import "../../../../styles/color-palette";
@import "../../../../styles/mixins";

.recent-deposits {
  h3 {
    font-weight: 600;
    font-size: 18px;
  }
}

.table {
  &.payouts-table {
    margin-bottom: 0;

    tbody {
      .light-text {
        color: $color-light-grey;
      }
    }
  }
}
