@import "theme";

// Variables
// =================================

$container-width: 1118px;
$mobile-width: 1199.98px;

$viewport-sm: 576px;
$viewport-md: 768px;
$viewport-lg: 992px;
$viewport-xl: 1200px;

$highchart-theme-blue: #1f87ee;
$highchart-theme-red: #ff5537;

$color-dark-grey: #656f79;
$color-grey: #f3f6fb;
$color-light-grey: #8896a5;
$color-xlight-grey: #b1bdc9;
$color-white: #fff;
$color-black: #000;
$color-green: #30c62e;
$color-red: #ff5537;
$color-blue: #1f87ee;
$grey-disabled: #c8c7c9;
$success-button-bg-color: #30c62e;
$danger-button-bg-color: #f26765;
$warning-button-bg-color: #f5a623;

$dark-mode-background-color: #0e0e10;

$bg-grey: #f3f6fb;

$border-color__light: #dadada;
$tags-background-color: #e1e6ef;

// inputs
$input-border-color: #d0d0d0;
$radio-button-color: #ced4da;

// Fonts
$font-regular: "Satoshi-Regular", "Noto Color Emoji", Arial, "sans-serif";
$font-medium: "Satoshi-Medium", "Noto Color Emoji", Arial, "sans-serif";
$font-bold: "Satoshi-Bold", "Noto Color Emoji", Arial, "sans-serif";
$font-variable: "Satoshi-Variable", "Noto Color Emoji", Arial, "sans-serif";

$header-bg-color: $color-white;
$nav-item-color: $color-black;

$font-size-small: 14px;
$font-size-default: 16px;
$font-size-md: 18px;

// Margin & Padding
$blocks-inside-padding: 20px;

// Shadow
$blocks-shadow: 10px 10px 30px rgba(162, 180, 196, 0.2);
$blocks-shadow-dark: 10px 10px 10px rgba(162, 180, 196, 0.1);
$dropdown-shadow: 10px 50px 50px rgba(0, 0, 0, 0.35);
$image-shadow: 0px 0px 7px rgba(162, 180, 196, 0.5);

// Radius
$blocks-radius: 8px;

// Buttons
$btn-outline-dark: $color-black;

$success-button-bg-color: $success-button-bg-color;
$success-button-shadow: 0px 3px 10px rgba(48, 198, 46, 0.32);

$danger-button-bg-color: $danger-button-bg-color;
$danger-button-shadow: 0px 3px 10px rgba(242, 103, 101, 0.32);

$warning-button-bg-color: $warning-button-bg-color;
//$warning-button-shadow:0px 3px 10px rgba(242, 103, 101, 0.32);

// Headings
$h1-font-size: 36px;
$h1-font-size-mobile: 28px;
$h2-font-size: 24px;
$h2-font-size-mobile: 20px;
$h3-font-size: 20px;
$h3-font-size-mobile: 16px;
$h4-font-size: 18px;
$h4-font-size-mobile: 14px;

// mixins
@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  -ms-box-shadow: $property;
  box-shadow: $property;
}

@mixin border-radius($radius-property) {
  -webkit-border-radius: $radius-property;
  -ms-border-radius: $radius-property;
  border-radius: $radius-property;
}
