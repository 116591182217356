// Variables
// =================================
@import "../../../../styles/color-palette";
@import "../../../../styles/mixins";

.recent-deposits {
  h3 {
    font-weight: 600;
    font-size: 18px;
  }
  .search-results-wrap {

    .user-profile-table {
      th {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: $color-light-grey;
      }
    }

    .table-outer {
      @include border-radius($blocks-radius);
      @include theme-aware("background", "table-background");
      box-shadow: none;

      font-size: 14px;
      padding: 0;
    }

    .table {
      margin-bottom: 0;
      font-family: $font-regular;

      &.table-records {
        th,
        td {
          padding-left: 25px;
          padding-right: 35px;
        }
      }

      thead th {
        vertical-align: middle;
      }

      tbody td {
        vertical-align: top;
        color: #818181;

        h4 {
          font-size: 14px;
          font-weight: 600;
        }

        .text-wrap {
          max-width: 50%;
        }

        .balance {
          font-family: $font-bold;
        }
      }
    }

    .table-hover tbody tr:hover {
      background-color: $color-grey;
    }

    .table-hover tbody .selected {
      @include theme-aware("background-color", "color-background");
    }
  }
}
