// Emoji
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

body {
  margin: 0;
  font-family: 'Satoshi-Regular', "Noto Color Emoji", Arial, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** Generated by FG **/
@font-face {
    font-family: 'HKGrotesk-Regular';
    src: url('./assets/fonts/HKGrotesk-Regular/HKGrotesk-Regular.eot');
    src: local('☺'), url('./assets/fonts/HKGrotesk-Regular/HKGrotesk-Regular.woff') format('woff'), url('./assets/fonts/HKGrotesk-Regular/HKGrotesk-Regular.ttf') format('truetype'), url('./assets/fonts/HKGrotesk-Regular/HKGrotesk-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HKGrotesk-Medium';
    src: url('./assets/fonts/HKGrotesk-Medium/HKGrotesk-Medium.woff2') format('woff2'),
         url('./assets/fonts/HKGrotesk-Medium/HKGrotesk-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/** Generated by FG **/
@font-face {
    font-family: 'HKGrotesk-Bold';
    src: url('./assets/fonts/HKGrotesk-Bold/HKGrotesk-Bold.eot');
    src: local('☺'), url('./assets/fonts/HKGrotesk-Bold/HKGrotesk-Bold.woff') format('woff'), url('./assets/fonts/HKGrotesk-Bold/HKGrotesk-Bold.ttf') format('truetype'), url('./assets/fonts/HKGrotesk-Bold/HKGrotesk-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-Bold';
    src: url('./assets/fonts/Satoshi-Bold/Satoshi-Bold.eot');
    src: url('./assets/fonts/Satoshi-Bold/Satoshi-Bold.woff') format('woff'), url('./assets/fonts/Satoshi-Bold/Satoshi-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('./assets/fonts/Satoshi-Medium/Satoshi-Medium.eot');
    src: url('./assets/fonts/Satoshi-Medium/Satoshi-Medium.woff') format('woff'), url('./assets/fonts/Satoshi-Medium/Satoshi-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-Regular';
    src: url('./assets/fonts/Satoshi-Regular/Satoshi-Regular.eot');
    src: url('./assets/fonts/Satoshi-Regular/Satoshi-Regular.woff') format('woff'), url('./assets/fonts/Satoshi-Regular/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-Variable';
    src: url('./assets/fonts/Satoshi-Variable/Satoshi-Variable.eot');
    src: url('./assets/fonts/Satoshi-Variable/Satoshi-Variable.woff') format('woff'), url('./assets/fonts/Satoshi-Variable/Satoshi-Variable.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
