@import "../../styles/_color-palette";
@import "../../styles/_mixins";
@import "../../styles/variables";

.paginator-custom {
  display: flex;
  // justify-content: center;
  align-items: center;
  // position: relative;

  .paginator-section {
    // justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .pagination {
      margin-bottom: 0;
			display: grid;
			grid-auto-flow: column;
			gap: 5px;

			.page-item {

				&.next, &.prev {
					padding: 0 .75rem;
				}

				.page-link {
					color: $primary-theme-color;
					font-weight: bold;
					box-shadow: none;
					border-radius: .25rem;
					border-color: transparent;

					&.active {
						background-color: $primary-button-bg-color;
						color: #fff;
						border-color: $primary-button-outline-color;
					}

				}

				&.disabled .page-link {
					background: none;
					color: #818181;
				}

			}
    }
  }

	.css-1w9j89e-menu {
		@include theme-aware("background-color", "dropdown-background");
		@include theme-aware("color", "icon-text");
		@include theme-aware("box-shadow", "dropdown-shadow");
		border: none;

		.css-1n7v3ny-option {
			@include theme-aware("background-color", "dropdown-hover");
			@include theme-aware("color", "title");
		}

		.css-1gegc2z-option {
			@include theme-aware("background-color", "dropdown-background");
			@include theme-aware("color", "icon-text");
		}

		.css-1gegc2z-option:hover {
			@include theme-aware("background-color", "dropdown-hover");
			@include theme-aware("color", "title");
		}

		.css-18aukwf-option {
			@include theme-aware("background-color", "dropdown-hover");
			@include theme-aware("color", "title");
		}
	}
}
