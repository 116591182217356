@import "../../../../styles/color-palette";
@import "../../../../styles/mixins";

.stripe-account {
  .external-account {
    background-color: $color-grey;
    padding: 15px 18px;
    border-radius: 0.25rem;

    svg {
      color: $color-light-grey;
    }
  }
}
