// Variables
// =================================
@import "../../../../styles/color-palette";
@import "../../../../styles/mixins";

.balance-tile {
  min-height: 270px;

  &.reduced-size {
    min-height: 200px;

    .title {
      font-size: 20px;
    }
  }

  .title {
    font-size: 22px;
  }

  .balance {
    font-weight: 600;
    color: $primary-theme-color;
    font-size: 48px;
    margin-bottom: 15px;
  }

  .available-balance {
    font-size: 17px;
    .currency-display {
      font-weight: bold;
      font-size: 20px;
      color: $color-dark-grey;
    }
  }

  .card-text {
    font-size: 14px;
    color: $color-light-grey;
  }

  .btn.btn-primary {
    padding: 12px 18px;
    font-size: 16px;
  }
}
