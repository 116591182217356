// Variables
// =================================
@import "../../../../styles/color-palette";
@import "../../../../styles/mixins";

.earnings-tile {
  min-height: 270px;

  &.card.card-content-custom {
    .card-title {
      margin: 0;
      margin-bottom: 10px;
    }

    .card-body {
      padding-bottom: 0;
    }
  }

  span {
    font-family: $font-bold;
    font-size: 22px;
    color: $primary-theme-color;
  }
}
