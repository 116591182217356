// Variables
// =================================
@import "../../styles/color-palette";
@import "../../styles/mixins";

@media (min-width: $viewport-md) {
  %absolute {
    position: absolute;
  }

  %margin-auto {
    margin: auto;
  }

  %center-horizontal {
    @extend %absolute;
    right: 0px;
    left: 0px;
    @extend %margin-auto;
  }

  %center-vertical {
    @extend %absolute;
    top: 0px;
    bottom: 0px;
    @extend %margin-auto;
  }

  %center-all {
    @extend %center-horizontal;
    @extend %center-vertical;
  }

  %sudos {
    content: " ";
    @extend %absolute;
  }

  .iphone {
    @include theme-aware("box-shadow", "dropdown-shadow");
    position: relative;
    height: 90vh;
    width: 450px;
    margin: 0px auto;
    border-radius: 50px;
  }

  .phone {
    @extend %absolute;
    bottom: 0px;
    right: 0px;
    width: 450px;
    height: 90vh;

    .island {
      width: 25%;
      height: 25px;
      background-color: $tags-background-color;
      border-radius: 100px;
      margin: 20px auto 10px auto;
    }

    .home {
      width: 30%;
      height: 5px;
      background-color: $color-dark-grey;
      border-radius: 100px;
      margin: 5px auto;
    }

    &:before {
      @extend %sudos;
      width: 100%;
      height: 100%;
      border: 8px solid $tags-background-color;
      border-radius: 50px;
    }
  }
}
