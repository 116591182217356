// Variables
// =================================
@import "../../styles/color-palette";
@import "../../styles/mixins";

.onboarding-container {
  position: relative;
  overflow-x: hidden;
  text-align: center;

  &::before {
    background: url("../../assets/img/onboarding_bg.svg") top left no-repeat;
    content: "";
    background-size: auto contain;
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }

  @media (min-width: $viewport-md) {
    &.container {
      height: 82vh;
      width: 435px;
      margin: 0 auto;
      border-radius: 40px;
    }
  }

  .step-three,
  .step-four,
  .step-six {
    text-align: left;
  }

  .step-one {
    overflow: hidden;

    .hero-img {
      @include theme-aware("background-color", "color-background");
    }
  }

  .step-two {
    .heading {
      font-size: 36px;
    }

    .lottie-container {
      position: relative;
      .lf-player-container {
        height: 125px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }

    .content-container {
      @include theme-aware("background-color", "color-background");
      position: relative;
      z-index: 10;
    }
  }

  .m-80 {
    margin-bottom: 80px;
  }

  .step-four,
  .step-six {
    margin-bottom: 125px;
  }

  .hero-img {
    max-width: 98vw;
    margin: 0 auto;

    @media (min-width: $viewport-md) {
      max-width: 400px;
    }
  }

  .heading {
    color: var(--grey-neutral-grey-900, #0d0d12);
    font-family: $font-bold;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
    letter-spacing: -0.8px;
    margin-bottom: 12px;
  }

  .subtitle {
    color: #2f2f2f;
    font-family: $font-bold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.4px;
  }

  .description {
    color: var(--grey-neutral-grey-400, #818898);
    font-family: $font-medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .gradient {
    background: -webkit-linear-gradient(103deg, #9076fc 16%, #61cfeb 88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .new-line {
    &::before {
      content: "\a";
      white-space: pre;
    }
  }

  .slick-slider {
    .slick-dots {
      @include theme-aware("background-color", "color-background");
      position: fixed;
      bottom: 80px;
      left: 0;
      z-index: 1000;

      @media (min-width: $viewport-md) {
        position: absolute;
        bottom: inherit;
      }

      li {
        margin: 0 2px;

        &.slick-active button:before {
          opacity: 0.45;
        }

        button {
          pointer-events: none;

          &::before {
            font-size: 10px;
            opacity: 0.1;
          }
        }
      }
    }
  }

  .talent-tier-card {
    padding: 24px;
    border-radius: 24px;
    border: 1px solid var(--grey-neutral-gey-50, #eceff3);
    background: #fff;
    box-shadow: 0px 0px 8px 2px rgba(16, 24, 40, 0.02);

    .card-icon {
      display: flex;
      width: 56px;
      height: 56px;
      padding: 10px 10px 6px 10px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid rgba(89, 37, 220, 0.1);
      background: radial-gradient(
          100% 100% at 50% 0%,
          rgba(189, 180, 254, 0.8) 18.61%,
          #a59ceb 100%
        ),
        #fff;
      box-shadow: 0px 5.25px 14px -3.5px rgba(99, 91, 255, 0.55);
    }

    .title {
      font-size: 28px;
    }

    .heading {
      font-size: 36px;
      font-family: $font-bold;
    }

    .subtitle {
      font-size: 16px;
    }

    .content-description {
      color: var(--grey-neutral-grey-400, #818898);
      font-family: $font-medium;
      font-size: 14px;
    }
  }

  .step-five {
    .hero-img {
      max-width: 80vw;

      @media (min-width: $viewport-md) {
        max-width: 400px;
      }
    }

    .lottie-container {
      position: relative;
      .lf-player-container {
        width: 300px;
        position: absolute;
        top: 30%;

        @media (min-width: $viewport-md) {
          width: 400px;
          top: 25%;
          left: -40px;
        }
      }
    }

    .form-group {
      .form-control {
        @include theme-aware("box-shadow", "shadow-dark");
        padding-right: 8px;
        background: var(--grey-neutral-grey-25, #f6f8fa);
        color: var(--grey-neutral-grey-400, #818898);
        font-family: $font-bold;
        font-size: 18px;
        text-align: right;
      }

      .input-group.input-with-addon {
        border-radius: 58px;
        background: var(--grey-neutral-grey-25, #f6f8fa);

        > * {
          border-radius: 58px;
        }

        *:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        *:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .btn.btn-primary {
      border-radius: 100px;
      background: var(--grey-neutral-grey-800, #1a1b25);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 28px;
    }
  }

  .step-six {
    .form-group {
      .form-label {
        color: var(--grey-neutral-grey-700, #272835);
        font-family: $font-bold;
        font-size: 16px;
      }

      .form-control {
        border-radius: 74px;
        background: var(--grey-neutral-grey-25, #f6f8fa);
        color: var(--grey-neutral-grey-400, #818898);
      }

      .single-select {
        margin-bottom: 8px;
        border-radius: 74px;
        background: var(--grey-neutral-grey-25, #f6f8fa);

        .css-1uccc91-singleValue {
          color: var(--grey-neutral-grey-400, #818898);
        }
        .css-1hb7zxy-IndicatorsContainer {
          .css-1okebmr-indicatorSeparator {
            background-color: transparent;
          }
        }

        .css-yk16xz-control,
        .css-1pahdxg-control {
          background-color: transparent;
        }
      }
    }
  }

  .sticky-container {
    width: 100%;

    .btn.btn-primary {
      border-radius: 100px;
    }

    @include theme-aware("box-shadow", "shadow-dark");
    @include theme-aware("background-color", "color-background");

    position: fixed;
    @media (min-width: $viewport-md) {
      position: absolute;
    }
    width: 100%;
    bottom: 0;
    left: 0;

    .button-container {
      margin: 0.5rem 1rem 1rem 1rem;
      @media (min-width: $viewport-md) {
        margin: 0.5rem 1rem;
      }
    }
  }
}
