@import "../styles/_color-palette";
@import "../styles/_mixins";
@import "../styles/variables";

.avatar-image-round {
  position: relative;
  display: inline-block;
	// width: 100%;
	// height: 100%;
  border-radius: 50%;

  .avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;

    &.avatar-small {
      height: 50px;
      width: 50px;
    }
    
    img {
      object-fit: cover;
      // color values are used for fallback image
      color: $color-dark-grey;
      background-color: $color-xlight-grey;
    }
  }

  .avatar-skeleton {
    height: 100px;
    width: 100px;
    aspect-ratio: 1;
  }

  .social-icon-img {
    position: absolute;
    z-index: 1;
    bottom: -4px;
    right: -4px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
}
